.pd-einstein-slick-slider-wrapper {
    margin-bottom: 40px;

    @include media-breakpoint-up(lg) {
        padding: 0 30px;
    }

    @include media-breakpoint-up(xl) {
        margin: 0 120px 100px;
    }

    .products-slider-header {
        text-align: center;
        margin-bottom: 30px;
        font-weight: $font-semibold;
        position: relative;

        h3 {
            line-height: 40px;

            &::before {
                content: '';
                height: 1px;
                width: 100%;
                background-color: $black;
                display: inline-block;
                top: 50%;
                left: 0;
                position: absolute;
                z-index: -1;
            }
        }

        span {
            @include font-size(40px);
            text-transform: uppercase;
            padding: 0 15px;
            background-color: $white;

            .italic {
                font-style: italic;
                text-transform: none;
            }
        }
    }

    .slick-slide {
        padding: 0 12px;
    }

    .slick-next {
        right: -20px;
    }

    .slick-prev {
        left: -20px;
    }

    .slick-next,
    .slick-prev {
        top: 30%;
    }

    .product-tile {
        margin: 0;
    }

    .slick-arrow {
        display: none !important;

        @include media-breakpoint-up(lg) {
            display: block !important;
        }
    }

    .slick-dots {
        @include media-breakpoint-up(lg) {
            position: absolute;
            left: -999px;
            z-index: -1;
            max-width: 100vw;
        }
    }
}
