.page-designer-reference {
    img,
    video {
        width: 100%;
    }

    .pd-slick-slider-wrapper,
    .carousel,
    .pd-row4-items,
    .customHTML-container,
    .mediaContainer,
    .plp-banner-wrapper-pd {
        margin-left: auto;
        margin-right: auto;
        max-width: 1440px;
    }

    .pd-slick-slider-wrapper,
    .carousel,
    .pd-row4-items {
        .customHTML-container,
        .mediaContainer,
        .plp-banner-wrapper-pd {
            max-width: none;
        }
    }

    .row4items-inner {
        .row-item {
            flex-grow: 1;
        }
    }

    .mediaContainer {
        .contentWrapper {
            > .wrapper-img-link {
                text-decoration: none;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .video_container {
        video {
            @include media-breakpoint-up(xxl) {
                max-height: 60vh;
            }
        }
    }
}

.pd-container {
    position: relative;
    width: 100%;
    flex: 0 0 100%;
    max-width: none;
    margin: 0 auto;

    .experience-component {
        &:first-child {
            @include headerNegativeMargin;
        }
    }

    .pd-slick-slide {
        position: relative;

        .mediaContent {
            position: absolute;

            // &.pos-static {
            //     position: static;
            // }
        }

        .button-link-abs {
            position: absolute;
            bottom: 10%;
            left: 10%;
        }

        .button-link {
            display: flex;
            justify-content: space-around;

            a {
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.pd-row4-items {
    &.animated {
        @include media-breakpoint-up(md) {
            overflow: hidden;
        }

        .row4items-inner {
            &:hover {
                .row-item {
                    @include media-breakpoint-up(md) {
                        width: 20%;
                    }
                }
            }

            .row-item {
                @include media-breakpoint-up(md) {
                    display: block;
                    width: 30%;
                    will-change: width;
                    transition: all 0.5s;
                    -webkit-backface-visibility: hidden;
                    -webkit-transform: translateZ(0) scale(1, 1);
                }

                &:hover {
                    @include media-breakpoint-up(md) {
                        width: 30%;
                    }
                }
            }

            .mediaImage {
                img {
                    @include media-breakpoint-up(md) {
                        transform: translate(-50%, -50%) translateZ(0) scale(1, 1);
                        -webkit-backface-visibility: hidden;
                    }
                }
            }
        }
    }
}

.plp-banner-pd-img {
    width: 100%;

    @include media-breakpoint-up(lg) {
        max-height: 43vh;
        object-fit: cover;
    }
}

.plp-banner-wrapper-pd {
    position: relative;
}

.plp-banner-pd-content {
    position: absolute;
    width: 472px;
    bottom: 40px;
}

.plp-banner-pd-header {
    @include font-size(28px, 36px);
    color: $grayscale-50;
    margin: 0 0 15px;
    font-weight: $font-semibold;

    @include media-breakpoint-up(xl) {
        @include font-size(36px, 48px);
    }
}

.plp-banner-pd-text1 {
    @include font-size(16px, 24px);
    color: $grayscale-50;
    font-weight: $font-semibold;
    margin: 0 0 10px;
}

.plp-banner-pd-text2 {
    @include font-size(16px, 24px);
    color: $grayscale-50;
    font-weight: $font-semibold;
    font-family: $base-font-family;
}

.buy-it-again {
    margin: 0 auto;
    max-width: 1440px;
    padding: 0 30px;

    .products-slider-header {
        text-align: center;
        margin-bottom: 30px;
        font-weight: $font-semibold;
        position: relative;

        h3 {
            line-height: 40px;

            &::before {
                content: '';
                height: 1px;
                width: 100%;
                background-color: $black;
                display: inline-block;
                top: 50%;
                left: 0;
                position: absolute;
                z-index: -1;
            }
        }

        span {
            @include font-size(40px);
            text-transform: uppercase;
            padding: 0 15px;
            background-color: $white;

            .italic {
                font-style: italic;
                text-transform: none;
            }
        }
    }

    .slick-slider {
        padding: 0 30px;
    }

    .slick-slide {
        padding: 0 12px;
    }
}
